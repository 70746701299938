import { createContext, useContext, useState } from "react";

export enum ContextAuthState {
  AuthVerification = "AUTH_ENTRY",
  DirectoryVerified = "DIRECTORY_VERIFIED",
  EditableDirectoryVerified = "EDITABLE_DIRECTORY_VERIFIED",
  FileVerified = "FILE_VERIFIED",
}

interface HSDPContextType {
  updateType: (type: ContextAuthState) => void;
  updateAccessId: (accessId: string) => void;
  storeHSDPToken: (token: string) => void;
  getHSDPToken: () => string;
  type: ContextAuthState;
  accessId: string | undefined;
}

const HSDPContext = createContext<HSDPContextType>(null!);

export function HSDPProvider({ children }: any) {
  const [type, setType] = useState<ContextAuthState>(
    ContextAuthState.AuthVerification
  );

  const [token, setToken] = useState<string>("")
  const [accessId, setAccessID] = useState<string | undefined>(undefined);

  const updateType = (type: ContextAuthState) => {
    setType(type);
  };

  const updateAccessId = (accessId: string) => {
    setAccessID(accessId);
  };

  const storeHSDPToken = (token: string) => {
    setToken(`Bearer ${token}`)
  }

  const getHSDPToken = () => {
    return token;
  }

  let value = { type, accessId, updateType, updateAccessId, storeHSDPToken, getHSDPToken };

  return <HSDPContext.Provider value={value}>{children}</HSDPContext.Provider>;
}

export const useHSDPAuth = () => {
  return useContext(HSDPContext);
};
