import React, { useState, useRef, useLayoutEffect, useContext } from "react";
import { useAsync } from "react-async-hook";
import { useAuthHeader } from "react-auth-kit";
import { EModal, ModalContext } from "../../contexts/ModalContext";
import queries from "../../network/queries";
import CreateLinkModal from "../modals/CreateLinkModal";
import ResetAccessModal from "../modals/ResetAccessModal";
import DeleteLinkModal from "../modals/DeleteLinkModal";
import SelectLinkModal from "../modals/SendLinkDetailsModal"

const headings = ["Key", "Name", "Type", "Client", "Date Created", "Expired"];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const TableItem = ({ children }: any) => {
  return (
    <React.Fragment>
      <th
        scope="col"
        className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
      >
        {children}
      </th>
    </React.Fragment>
  );
};

const ShareableLinksTable: React.FC = () => {
  const checkbox = useRef<any>();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const authHeader = useAuthHeader();

  const { result, execute } = useAsync(queries.getShareableLinks, [
    authHeader(),
  ]);
  const { activateModal, assignModalValue } = useContext(ModalContext);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedRows.length > 0 && selectedRows.length < result?.data.length;
    setChecked(selectedRows.length === result?.data.length);
    setIndeterminate(isIndeterminate);

    checkbox.current.indeterminate = isIndeterminate;
  }, [result?.data.length, selectedRows]);

  const openModal = () => {
    activateModal(EModal.CreateLink);
  };

  const openResetAccessCodeModal = () => {
    assignModalValue(EModal.ResetAccessCode, selectedRows[0]);
    activateModal(EModal.ResetAccessCode);
  };

  const openSendLinkDetailsModal = () => {
    assignModalValue(EModal.SendLinkDetails, selectedRows[0]);
    activateModal(EModal.SendLinkDetails);
  };

  const openDeleteLinkModal = () => {
    assignModalValue(EModal.DeleteLink, selectedRows[0]);
    activateModal(EModal.DeleteLink);
  };

  function toggleAll() {
    setSelectedRows(checked || indeterminate ? [] : result?.data);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  return (
    <>
      <CreateLinkModal refetch={execute} />
      <ResetAccessModal {...selectedRows[0]} />
      <DeleteLinkModal refetch={execute} />
      <SelectLinkModal {...selectedRows[0]} />

      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Shareable Links
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the links in your account. Create links that allow
              you to share files or directories with people outside your
              organisation.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              onClick={openModal}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Create link
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="relative overflow-hidden">
                {selectedRows.length === 1 && (
                  <div>
                    <div className="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16">
                      <button
                        type="button"
                        className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                        onClick={openResetAccessCodeModal}
                      >
                        Reset access code
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                        onClick={openSendLinkDetailsModal}
                      >
                        Send link details
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                        onClick={openDeleteLinkModal}
                      >
                        Delete link
                      </button>
                    </div>
                  </div>
                )}
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="relative w-12 px-6 sm:w-16 sm:px-8"
                      >
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                          ref={checkbox}
                          checked={checked}
                          onChange={toggleAll}
                        />
                      </th>
                      {headings.map((heading) => (
                        <TableItem>{heading}</TableItem>
                      ))}
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {result?.data.map((link: any) => (
                      <tr
                        key={link.key}
                        className={
                          selectedRows.includes(link) ? "bg-gray-50" : undefined
                        }
                      >
                        <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                          {selectedRows.includes(link) && (
                            <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                          )}
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                            value={link.email}
                            checked={selectedRows.includes(link)}
                            onChange={(e) => {
                              setSelectedRows(
                                e.target.checked
                                  ? [...selectedRows, link]
                                  : selectedRows.filter((p) => p !== link)
                              );
                            }}
                          />
                        </td>
                        <td
                          className={classNames(
                            "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                            selectedRows.includes(link)
                              ? "text-indigo-600"
                              : "text-gray-900"
                          )}
                        >
                          {link.key || "Unknown"}
                        </td>
                        <td
                          className={classNames(
                            "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                            selectedRows.includes(link)
                              ? "text-indigo-600"
                              : "text-gray-900"
                          )}
                        >
                          {link.name || "Unknown"}
                        </td>
                        <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                          {link.type || "Unknown"}
                        </td>
                        <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                          {link.client || "Stormloop"}
                        </td>
                        <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                          {link.date_created || "Unknown"}
                        </td>
                        <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                          {link.expiry || "Unknown"}
                        </td>

                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <a
                            href={`/access/${link.key}`}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            View Link
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareableLinksTable;
