import React, { useEffect, useCallback } from "react";
import { useAsync } from "react-async-hook";
import { useAuthHeader } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import CreateDirectoryModal from "../../../components/modals/CreateDirectoryModal";
import queries from "../../../network/queries";

const DirectoryLayout: React.FC = () => {
  const navigation = useNavigate();
  const authHeader = useAuthHeader();
  const { result, execute } = useAsync(queries.getDirectories, [authHeader()]);

  const onDirectoryClick = useCallback(
    (ref: any) => {
      const { id } = ref?.target;
      navigation({ pathname: `/directories/${id}` });
    },
    [navigation]
  );

  useEffect(() => {
    async function processDirectories() {
      await execute(authHeader());
    }

    processDirectories();
  }, []);

  return (
    <>
      <CreateDirectoryModal title="Create Directory" execute={execute} />
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {result &&
          result.data.map((file: any) => (
            <li key={file.id} className="relative">
              <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                <img
                  src={file.source}
                  alt=""
                  className="object-cover pointer-events-none group-hover:opacity-75"
                />
                <button
                  id={file.id}
                  onClick={onDirectoryClick}
                  type="button"
                  className="absolute inset-0 focus:outline-none"
                >
                  <span className="sr-only">
                    View details for {file.directory}
                  </span>
                </button>
              </div>
              <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
                {file.directory}
              </p>
              <p className="block text-sm font-medium text-gray-500 pointer-events-none">
                {file.size}
              </p>
            </li>
          ))}
      </ul>
    </>
  );
};

export default DirectoryLayout;
