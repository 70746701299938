/* eslint-disable import/no-anonymous-default-export */
import { ContextAuthState, useHSDPAuth } from "../../contexts/HSDPContext";
import AccessCode from "./AccessCode";
import SecureDirectoryLayout from "./SecureDirectoryLayout";
import SecureEditableDirectoryLayout from "./SecureEditableDirectoryLayout"
import SecureFileDownload from "./SecureFileDownload";

export default () => {
  const { type, accessId } = useHSDPAuth();

  const renderAuth = () => {
    switch (type) {
      case ContextAuthState.AuthVerification:
        return <AccessCode />;

      case ContextAuthState.DirectoryVerified:
        return <SecureDirectoryLayout />;

      case ContextAuthState.EditableDirectoryVerified:
        return <SecureEditableDirectoryLayout key={accessId} />;

      case ContextAuthState.FileVerified:
        return <SecureFileDownload />;

      default:
        return <AccessCode />;
    }
  };

  return <div>{renderAuth()}</div>;
};
