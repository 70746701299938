/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { CONFIG } from "../../utilities/config";

const { apiUrl } = CONFIG;

async function createUser(token: string, params: any): Promise<any> {
  const { data } = await axios.post(
    `${apiUrl}/v1/admin/user`,
    params,
    { headers: { Authorization: token } }
  );

  return data;
}

async function updateUserRole(
  token: string,
  params: { email: string; role: string }
) {
  const { data } = await axios.patch(
    `${apiUrl}/v1/admin/role`,
    params,
    { headers: { Authorization: token } }
  );

  return data;
}

async function createShareableLink(token: string, params: any) {
  const { data } = await axios.post(
    `${apiUrl}/v1/link`,
    params,
    { headers: { Authorization: token } }
  );

  return data;
}

async function initialiseLink(token: string, params: any) {
  const { data } = await axios.post(
    `${apiUrl}/v1/link/initialise`,
    params,
    { headers: { Authorization: token } }
  );

  return data;
}

async function verifyLink(token: string, params: any) {
  const { data } = await axios.post(
    `${apiUrl}/v1/link/verify`,
    params,
    { headers: { Authorization: token } }
  );

  return data;
}

async function uploadFile(params: any): Promise<any> {
  const { directory, filename, file, company, token } = params;

  const formData = new FormData();
  const { files } = file;
  const [inputFile] = files as File[];

  formData.append("file", inputFile, inputFile.name);

  const { data } = await axios.put(
    `${apiUrl}/v1/file/${directory}/${filename}`,
    formData,
    {
      params: {
        company,
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    }
  );

  return data;
}

async function deleteLink(token: string, params: any): Promise<any> {
  const { data } = await axios.delete(
    `${apiUrl}/v1/link/${params.key}`,
    {
      headers: { Authorization: token },
    }
  );

  return data;
}

async function resetShareableLink(
  token: string,
  accessCode: string,
  accessKey: string
) {
  const { data } = await axios.patch(
    `${apiUrl}/v1/link/reset`,
    {
      accessCode,
      accessKey,
    },
    {
      headers: { Authorization: token },
    }
  );

  return data;
}

async function createDirectory(
  token: string,
  directory: string,
  company: string,
  user?: string
) {
  const { data } = await axios.post(
    `${apiUrl}/v1/directory`,
    {
      directory,
      client: company,
      user,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return data;
}

async function sendLinkDetails(token: string, params: any) {
  const { data } = await axios.post(
    `${apiUrl}/v1/link/email`,
    params,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return data;
}

async function loginUser(email: string, password: string): Promise<any> {
  const { data } = await axios.post(`${apiUrl}/v1/identity/login`, {
    email,
    password,
  });

  return data;
}

export default {
  uploadFile,
  loginUser,
  createDirectory,
  createUser,
  createShareableLink,
  initialiseLink,
  deleteLink,
  verifyLink,
  resetShareableLink,
  updateUserRole,
  sendLinkDetails,
};
