import {
  ChangeEvent,
  useCallback,
  useContext,
  useState,
  useEffect,
} from "react";
import Modal from "../Modal";
import InputElement from "../form/InputElement";
import { EModal, ModalContext } from "../../contexts/ModalContext";
import { useAsyncCallback } from "react-async-hook";
import mutation from "../../network/mutation";
import { useAuthHeader } from "react-auth-kit";

enum ELinkOperation {
  Reset = "RESET",
  Initialise = "INITIALISE",
}

type ResetAccessModalProps = {
  client: string;
  key: string;
  name: string;
  type: string;
};

const ResetAccessModal = (props: ResetAccessModalProps): JSX.Element => {
  const [accessCode, setAccessCode] = useState<string>("");

  const { clearValues, getModalValue, getModalStatus } =
    useContext(ModalContext);
  const modalStatus = getModalStatus(EModal.ResetAccessCode);
  const modalValue = getModalValue(EModal.ResetAccessCode) as any;

  const mutateResetShareable = useAsyncCallback(mutation.resetShareableLink);
  const mutateInitialiseCall = useAsyncCallback(mutation.initialiseLink);

  const authHeader = useAuthHeader();
  const token = authHeader();


  useEffect(() => {
    const initialiseLinkModal = async () => {
     
      const result = await mutateInitialiseCall.execute(token, {
        type: ELinkOperation.Reset,
        accessKey: modalValue?.key,
      });

      const { accessCode: code } = result;
      setAccessCode(code);
    };

    if (modalStatus === true) {
      initialiseLinkModal();
    }

  }, [modalStatus, modalValue, token]);

  const updateAccessCode = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setAccessCode(value);
    },
    []
  );

  const resetLink = useCallback(async () => {
    await mutateResetShareable.execute(token, accessCode, modalValue?.key);
    clearValues();
  }, [mutateResetShareable, token, accessCode, modalValue?.key, clearValues]);

  return (
    <Modal
      confirmButton="Reset Link"
      open={modalStatus}
      onClick={resetLink}
      onClose={clearValues}
    >
      <div className="space-y-12 divide-y divide-gray-200 sm:space-y-8">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Reset Shareable Link: {modalValue?.key}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Reset the link currently shown to regain access to resource.
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <InputElement
              id={"key"}
              name={"Key"}
              type="text"
              disabled={true}
              value={modalValue?.key}
            />
            <InputElement
              id={"accessCode"}
              name={"Access Code"}
              type="text"
              disabled={true}
              onChange={updateAccessCode}
              value={accessCode}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ResetAccessModal;
