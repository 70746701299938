import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { RequireAuth, AuthProvider } from "react-auth-kit";
import SignIn from "./screens/Auth/SignIn";
import "./index.css";
import Dashboard from "./screens/Application/Dashboard";
import ApplicationIndex from "./screens/Application";
import File from "./screens/Application/File";
import Directory from "./screens/Application/Directory";
import ShareableLinks from "./screens/Application/ShareableLinks";
import Client from "./screens/Application/Client";
import FileLayout from "./screens/Application/FileLayout";
import ModalProvider from "./contexts/ModalContext";
import AccessCode from "./screens/Auth";
import { HSDPProvider } from "./contexts/HSDPContext";

function Redirect({ children }: any) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate({ pathname: "/dashboard" });
  }, [navigate]);

  return <React.Fragment>{children}</React.Fragment>;
}

function App() {
  return (
    <AuthProvider
      authType="cookie"
      authName="_auth"
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === "https:"}
    >
      <ModalProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Redirect path="/dashboard" />} />
            <Route path="/login" element={<SignIn />} />
            <Route
              path="/access/:accessKey"
              element={
                <HSDPProvider>
                  <AccessCode />
                </HSDPProvider>
              }
            />
            <Route
              path="/dashboard"
              element={
                <RequireAuth loginPath="/login">
                  <ApplicationIndex>
                    <Dashboard />
                  </ApplicationIndex>
                </RequireAuth>
              }
            />
            <Route
              path="/files"
              element={
                <RequireAuth loginPath="/login">
                  <ApplicationIndex>
                    <File />
                  </ApplicationIndex>
                </RequireAuth>
              }
            />
            <Route
              path="/links"
              element={
                <RequireAuth loginPath="/login">
                  <ApplicationIndex>
                    <ShareableLinks />
                  </ApplicationIndex>
                </RequireAuth>
              }
            />
            <Route
              path="/clients"
              element={
                <RequireAuth loginPath="/login">
                  <ApplicationIndex>
                    <Client />
                  </ApplicationIndex>
                </RequireAuth>
              }
            />
            <Route
              path="/directories"
              element={
                <RequireAuth loginPath="/login">
                  <ApplicationIndex>
                    <Directory />
                  </ApplicationIndex>
                </RequireAuth>
              }
            />
            <Route
              path="/directories/:directory"
              element={
                <RequireAuth loginPath="/login">
                  <ApplicationIndex>
                    <FileLayout />
                  </ApplicationIndex>
                </RequireAuth>
              }
            />
          </Routes>
        </BrowserRouter>
      </ModalProvider>
    </AuthProvider>
  );
}

export default App;
