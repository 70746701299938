import React from "react"


const File = (): JSX.Element => {
    return (
        <div>
            Hello World
        </div>
    )
}

export default File