import { useCallback, useContext, useRef } from "react";
import { useAsyncCallback } from "react-async-hook";

import Modal from "../Modal";
import mutation from "../../network/mutation";
import FileInput from "../form/FileInput";
import { EModal, ModalContext } from "../../contexts/ModalContext";
import { useHSDPAuth } from "../../contexts/HSDPContext";

const UploadEditableFileModal = ({ key, callback }: any): JSX.Element => {
  const fileRef = useRef<HTMLInputElement>();
  const mutateUploadFile = useAsyncCallback(mutation.uploadFile);
  const { accessId } = useHSDPAuth();

  const { getModalStatus, activateModal, clearValues } =
    useContext(ModalContext);
  const status = getModalStatus(EModal.UploadEditableDirectoryFile);

  const uploadFile = useCallback(async () => {
    const { files } = fileRef.current as HTMLInputElement;
    const [file] = files as any;

    await mutateUploadFile.execute({
      type: "LINK_EDITABLE_DIRECTORY",
      key: accessId,
      directory: accessId,
      filename: file?.name,
      file: fileRef.current,
    });

    activateModal(EModal.Clear);
    callback()
    clearValues()
  }, [accessId, activateModal, callback, clearValues, mutateUploadFile]);


  return (
    <Modal
      open={status}
      onClick={uploadFile}
      onClose={() => {
        activateModal(EModal.Clear);
        clearValues();
      }}
    >
      <div className="space-y-12 divide-y divide-gray-200 sm:space-y-8">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Directory
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Any file uploaded will be stored in an secure environment where
              authorised personnel will be able to view the files you have
              uploaded.
            </p>
          </div>

          <div className="mt-6 mb-12 sm:mt-5 space-y-12 sm:space-y-5">
            <FileInput ref={fileRef} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadEditableFileModal;
