
/**
 * An enviroment specific configuration.
 */
export interface IConfig {
    apiUrl: string;
}

/**
 * The current config for the current enviroment.
 */
export const CONFIG: IConfig = getConfig();

/**
 * Gets the config depending on the enviroment.
 */
export function getConfig(env: typeof process.env.NODE_ENV = process.env.NODE_ENV): IConfig {
    switch (env){
        case "production":
            return require('../config.prod.json');
        case "development":
        case "test":
            return require('../config.dev.json');
        default:
            throw new Error(`Unknown enviroment: ${env}`);
    }
}