import React from "react";
import ShareableLinksTable from "../../../components/tables/ShareableLinksTable";
import Card from "../../../layout/Card";

const ShareableLinks: React.FC = () => {
  return (
    <React.Fragment>
      <Card>
        <ShareableLinksTable />
      </Card>
    </React.Fragment>
  );
};

export default ShareableLinks;
