import { ChangeEvent, useCallback, useContext, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import { useAuthHeader } from "react-auth-kit";
import { ModalContext, EModal } from "../../contexts/ModalContext";
import mutation from "../../network/mutation";
import InputElement from "../form/InputElement";
import InputSelectMenu from "../form/InputSelectMenu";
import Modal from "../Modal";

const UpdateUserModal = ({ refetch, title }: any): JSX.Element => {
  const [role, setRole] = useState<string>("NEPTUNE_USER");

  const authHeader = useAuthHeader();

  const roles = [
    { value: "NEPTUNE_USER", label: "User" },
    { value: "NEPTUNE_ADMIN", label: "Admin" },
  ];

  const { clearValues, getModalStatus, getModalValue } =
    useContext(ModalContext);
    
  const status = getModalStatus(EModal.UpdateUserRole);
  const modalParams = getModalValue(EModal.UpdateUserRole);
  const mutateUpdateUser = useAsyncCallback(mutation.updateUserRole);

  const updateUserRole = useCallback(async () => {
    const params = {
      email: modalParams?.email,
      role: role,
    };

    const token = authHeader();

    await mutateUpdateUser.execute(token, params);
    await refetch(token);
    clearValues();
  }, [authHeader, clearValues, modalParams?.email, mutateUpdateUser, refetch, role]);

  const updateRole = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setRole(value);
  };

  return (
    <Modal
      open={status}
      confirmButton="Update Role"
      onClick={updateUserRole}
      onClose={() => clearValues()}
    >
      <div className="space-y-12 divide-y divide-gray-200 sm:space-y-8">
        <div>
          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Update user role for a specific user
            </p>
            <InputElement
              id={"name"}
              name={"Name"}
              type="text"
              value={modalParams?.name}
            />
            <InputSelectMenu
              title={"Role"}
              onChange={updateRole}
              placeholder="Please select a role from the list..."
              options={roles}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateUserModal;
