import React from "react";

type InputElementProps = {
  id: string;
  ref?: any;
  name: string;
  type: string;
  onChange?: Function;
  disabled?: boolean;
  value?: any;
};

const InputElement = (props: InputElementProps) => {
  const applyInputProps = () => {
    let value = "Pending Generation";

    switch (props.type) {
      case "text":
        value = props.value
        break;

      case "input":
        break;

      default:
        value = props.value;
    }

    const inputProps = {
      onChange: (e: any) => {
        if (typeof props.onChange !== "undefined") {
          props.onChange(e.target.value);
        }
      },
      value,
    };

    return inputProps;
  };

  const inputProps = applyInputProps()

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor="username"
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {props.name}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="max-w-lg flex rounded-md shadow-sm">
          <input
            title={props.id}
            disabled={props.disabled}
            type={props.type}
            name={props.id}
            id={props.id}
            className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
            {...inputProps}
            onChange={(e) => props.onChange && props?.onChange(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default InputElement;
