import axios from "axios";
import { User } from "../types/userTypes";
import { CONFIG } from "../utilities/config";

const { apiUrl } = CONFIG;

/**
 * Registers a new user
 * @param {string} name full name (first last). 3-30 characters
 * @param {string} email 3-30 characters
 * @param {string} password minimum of 8 characters
 * @param {Date} dateOfBirth
 * @returns {Promise<string | null>} User UID on success, null on failure
 */
export async function registerUser(
  name: string,
  email: string,
  password: string,
  dateOfBirth: Date
): Promise<string | null> {
  const resp = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/v1/identity/register`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        password,
        dateOfBirth: dateOfBirth.toLocaleDateString("en-US"),
      }),
    }
  );
  if (resp.status === 200) {
    const { id } = await resp.json();
    return id;
  }
  return null;
}

// TODO: temp - implement with API
export async function signInUser(
  email: string,
  password: string
): Promise<any> {
  const { data } = await axios.post(`${apiUrl}/v1/user/identity`, {
    email,
    password,
  });
  sessionStorage.setItem("x-user-token", data?.token);

  const respData: { user: any; payload: any } = {
    payload: data,
    user: data?.info,
  };

  return { user: respData.user, payload: data.token };
}

/**
 * Retrvies the current user from client localStorage.
 * @returns {User | null} User object, or null if no user.
 */
export function getCurrentUser(): User | null {
  const user = localStorage.getItem("currentUser");
  if (user === null) {
    return null;
  }
  return JSON.parse(user) as User;
}

/**
 * Remove user (and attached auth tokens) from local storage
 */
export function logOutUser() {
  localStorage.removeItem("currentUser");
  window.dispatchEvent(new CustomEvent("userChange"));
}

// TODO: implement with API
export async function refreshAccessToken(): Promise<string | null> {
  return "todoAccessToken";
}

/**
 * Stores user in localStorage as "currentUser" and fires custom event for AuthContext to pick up
 * @param {User} user
 */
export function storeUser(user: User) {
  localStorage.setItem("currentUser", JSON.stringify(user));
}
