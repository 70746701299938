import { createContext, useContext } from "react";
import Button from "../layout/Button";
import { EModal, ModalContext } from "./ModalContext";

export enum LayoutState {
  FileCreationHeader = "FILE_CREATION_HEADER",
  DirectoryCreationHeader = "DIRECTORY_CREATION_HEADER",
  LinkCreationHeader = "LINK_CREATION_HEADER",
}

interface LayoutContextType {
  renderLayoutOptions: (type: LayoutState) => void;
  getActiveLayout: (type: LayoutState) => boolean;
}

const LayoutContext = createContext<LayoutContextType>(null!);

export function LayoutProvider({ children }: any) {
  const { activateModal } = useContext(ModalContext);

  const renderLayoutOptions = (type: LayoutState) => {
    switch (type) {
      case LayoutState.FileCreationHeader:
        return (
          <Button onClick={() => activateModal(EModal.UploadFile)}>
            Upload File
          </Button>
        );

      case LayoutState.DirectoryCreationHeader:
        return (
          <Button onClick={() => activateModal(EModal.CreateDirectory)}>
            Create Directory
          </Button>
        );

      case LayoutState.LinkCreationHeader:
        return (
          <Button onClick={() => activateModal(EModal.CreateDirectory)}>
            Create Link
          </Button>
        );
    }
  };

  const getActiveLayout = (type: LayoutState) => {
    return false;
  };

  let value = { renderLayoutOptions, getActiveLayout };

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
}
