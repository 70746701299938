import {
  ChangeEvent,
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
} from "react";
import Modal from "../Modal";
import InputElement from "../form/InputElement";
import { EModal, ModalContext } from "../../contexts/ModalContext";
import InputSelectMenu from "../form/InputSelectMenu";
import { useAsync, useAsyncCallback } from "react-async-hook";
import mutation from "../../network/mutation";
import queries from "../../network/queries";
import { useAuthHeader } from "react-auth-kit";

const mapDirectory = (item: any) => {
  return {
    value: item?.id,
    label: item?.title,
  };
};

const mapFile = (item: any) => {
  return {
    value: item?.id,
    label: item?.file_name,
  };
};

const mapCompany = (item: any) => {
  return {
    value: JSON.stringify({
      id: item?.id,
      name: item?.name,
      directories: item?.directories,
    }),
    label: item?.name,
  };
};

const emptyString = (value: any) => {
  if (typeof value === "string") {
    return value.length! > 0;
  }

  if (typeof value === "object") {
    return Object.keys(value).length > 0;
  }
};

enum ELinkType {
  File = "LINK_FILE",
  Directory = "LINK_DIRECTORY",
  EditableDirectory = "LINK_EDITABLE_DIRECTORY",
}

const CreateLinkModal = ({ refetch }: any): JSX.Element => {
  const [directory, setDirectory] = useState<string>("");
  const [company, setCompany] = useState<any>("");
  const [expiry, setExpiryDate] = useState<any>("");
  const [linkType, setLinkType] = useState<any>("");
  const authHeader = useAuthHeader();

  const [file, setFile] = useState<any>();

  const [accessCode, setAccessCode] = useState<string>("");
  const [key, setKey] = useState<string>("");

  const [initialise, setInitialise] = useState<boolean>(true);
  const [directories, setDirectories] = useState<any[]>([]);

  const { clearValues, getModalStatus } = useContext(ModalContext);
  const modalStatus = getModalStatus(EModal.CreateLink);

  const { result: companyResult } = useAsync(queries.getCompanies, [
    authHeader(),
  ]);
  const mutateCreateLink = useAsyncCallback(mutation.createShareableLink);
  const mutateInitialiseCall = useAsyncCallback(mutation.initialiseLink);

  const directoryRef = useRef<any>();
  const companyRef = useRef<any>();

  const { result: fileResult } = useAsync(queries.queryFiles, [
    authHeader(),
    directory,
  ]);
  const token = authHeader();

  useEffect(() => {
    const initialiseLinkModal = async () => {
      const result = await mutateInitialiseCall.execute(token, {
        company: company?.id,
        directory,
      });

      const { accessCode, key } = result;
      setAccessCode(accessCode);
      setKey(key);

      setInitialise(false);
    };

    if (initialise === true && emptyString(company) && emptyString(directory)) {
      setInitialise(false);
      initialiseLinkModal();
    }
  }, [company, directory, initialise, mutateInitialiseCall, token]);

  const updateDirectory = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      setDirectory(value);
    },
    []
  );

  const updateFile = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setFile(value);
  };

  const updateLinkType = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setLinkType(value);
  };

  const isLinkTypeDisabled = () => {
    if (company === "" || directory === "") {
      return true;
    }

    return false;
  };

  const updateCompany = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const parsedValue = JSON.parse(value);

    setCompany({ id: parsedValue.id, name: parsedValue.name });
    setDirectories(parsedValue.directories);
  };

  const updateExpiryDate = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setExpiryDate(value);
  };

  const createLink = useCallback(async () => {
    switch (linkType) {
      case ELinkType.Directory:
        await mutateCreateLink.execute(token, {
          linkType,
          directory,
          key,
          accessCode,
          expiry,
        });
        clearValues();
        refetch(authHeader());
        return;

      case ELinkType.File:
        await mutateCreateLink.execute(token, {
          linkType,
          file,
          key,
          accessCode,
          expiry,
        });
        clearValues();
        refetch(authHeader());
        return;

      case ELinkType.EditableDirectory:
        await mutateCreateLink.execute(token, {
          linkType,
          directory,
          key,
          accessCode,
          expiry,
        });
        clearValues();
        refetch(authHeader());
        return;
    }
  }, [accessCode, authHeader, clearValues, directory, expiry, file, key, linkType, mutateCreateLink, refetch, token]);

  const linkTypeDisabled = isLinkTypeDisabled();

  return (
    <Modal
      confirmButton="Create Link"
      open={modalStatus}
      onClick={() => createLink()}
      onClose={() => clearValues()}
    >
      <div className="space-y-12 divide-y divide-gray-200 sm:space-y-8">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Create Shareable Link
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              This information could be displayed publicly without an access
              token so be careful what you share.
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <InputSelectMenu
              ref={companyRef}
              title={"Company"}
              onChange={updateCompany}
              mapper={mapCompany}
              placeholder="Please select a company from the list..."
              options={companyResult?.data || []}
            />
            <InputSelectMenu
              ref={directoryRef}
              title={"Directory"}
              onChange={updateDirectory}
              mapper={mapDirectory}
              placeholder="Please select a directory from the list..."
              options={directories || []}
            />
            <InputSelectMenu
              title={"Link Type"}
              onChange={updateLinkType}
              placeholder="Please select a link from the list..."
              disabled={linkTypeDisabled}
              options={[
                { value: ELinkType.File, label: "File" },
                { value: ELinkType.Directory, label: "Directory" },
                {
                  value: ELinkType.EditableDirectory,
                  label: "Editable Directory",
                },
              ]}
            />
            {linkType === ELinkType.File && (
              <InputSelectMenu
                title={"Files"}
                onChange={updateFile}
                mapper={mapFile}
                placeholder="Please select a file from the list..."
                options={fileResult?.data}
              />
            )}
            <InputElement
              id={"expiry"}
              name="Expiry Date"
              type="date"
              onChange={updateExpiryDate}
            />
            <InputElement
              id={"key"}
              name={"Key"}
              type="text"
              disabled={true}
              value={key}
            />
            <InputElement
              id={"accessCode"}
              name={"Access Code"}
              type="text"
              disabled={true}
              value={accessCode}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateLinkModal;
