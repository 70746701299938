import { ChangeEvent, useCallback, useContext, useRef, useState } from "react";
import { useAsync, useAsyncCallback } from "react-async-hook";
import Modal from "../Modal";
import mutation from "../../network/mutation";
import queries from "../../network/queries";
import InputSelectMenu from "../form/InputSelectMenu";
import InputElement from "../form/InputElement";
import { EModal, ModalContext } from "../../contexts/ModalContext";
import { useAuthHeader } from "react-auth-kit";

const mapCompany = (company: any) => ({
  value: company.id,
  label: company.name,
});

/** @todo modal structure needs to be refactored to make it simpler, we either use ref or state not both*/

const CreateUserModal = ({ title, refetch }: any): JSX.Element => {
  const [company, setCompany] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const authHeader = useAuthHeader()
  const token = authHeader()

  const users = [
    { value: "NEPTUNE_USER", label: "User" },
    { value: "NEPTUNE_ADMIN", label: "Admin" },
  ];

  const emailRef = useRef<any>();
  const nameRef = useRef<any>();
  const roleRef = useRef<any>();
  const companyRef = useRef<any>();
  const passwordRef = useRef<any>();

  const mutateCreateUser = useAsyncCallback(mutation.createUser);
  const { result: companyResult } = useAsync(queries.getCompanies, [authHeader()]);

  const { clearValues, getModalStatus } = useContext(ModalContext);
  const modalStatus = getModalStatus(EModal.CreateUser);

  const updatePassword = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setPassword(value);
  }, []);

  const updateEmail = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setEmail(value);
  }, []);

  const updateName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setName(value);
  }, []);

  const updateCompany = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setCompany(value);
  }, []);

  const updateRole = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setRole(value);
  }, []);

  const createUser = useCallback(async () => {
    const params = {
      name,
      email,
      company,
      role,
      password,
      dateOfBirth: new Date(),
    };

    await mutateCreateUser.execute(token, params);
    await refetch();

    clearValues();
  }, [name, email, company, role, password, mutateCreateUser, token, refetch, clearValues]);

  return (
    <Modal
      open={modalStatus}
      onClick={createUser}
      onClose={() => clearValues()}
      confirmButton="Create User"
    >
      <div className="space-y-12 divide-y divide-gray-200 sm:space-y-8">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Create a user that will have access to the system.
            </p>
          </div>
          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <InputElement
              ref={nameRef}
              id={"name"}
              name={"Name"}
              type="text"
              onChange={updateName}
            />
            <InputElement
              ref={emailRef}
              id={"email"}
              name={"Email"}
              type="text"
              onChange={updateEmail}
            />
            <InputElement
              ref={passwordRef}
              id={"password"}
              name={"Password"}
              type="password"
              onChange={updatePassword}
            />
            <InputSelectMenu
              ref={roleRef}
              title={"Role"}
              onChange={updateRole}
              placeholder="Please select a role from the list..."
              options={users}
            />
            <InputSelectMenu
              ref={companyRef}
              title={"Company"}
              onChange={updateCompany}
              placeholder="Please select a company from the list..."
              mapper={mapCompany}
              options={companyResult?.data || []}
            />
          </div>
          <br />
        </div>
      </div>
    </Modal>
  );
};

export default CreateUserModal;
