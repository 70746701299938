import React from "react";
import ClientTable from "../../../components/tables/ClientTable";
import Card from "../../../layout/Card";

const Client: React.FC = () => {
  return (
    <React.Fragment>
      <Card>
        <ClientTable />
      </Card>
    </React.Fragment>
  );
};

export default Client;
