import { useContext } from "react";
import UploadEditableFileModal from "../components/modals/UploadEditableFileModal";
import Button from "./Button";
import { EModal, ModalContext } from "../contexts/ModalContext";

type StackedLayoutHeaderParams = {
  name: string;
  modal: {
    text: string;
    type: EModal;
    values: any;
    callback?: Function;
  };
  accessId?: string;
};

const InnerStackedLayoutHeader: React.FC<any> = ({
  name,
  modal,
  accessId,
}: StackedLayoutHeaderParams) => {
  const { activateModal } = useContext(ModalContext);
  const renderResouceComponent = () => {
    return (
      <Button
        onClick={() => {
          activateModal(modal.type);
        }}
      >
        {modal.text}
      </Button>
    );
  };

  return (
    <>
      <UploadEditableFileModal
        key={accessId}
        callback={() => {
          if (typeof modal.callback !== "undefined") {
            modal.callback(modal.values.token, modal.values.access);
          }
        }}
      />
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 grid gap-4 grid-cols-2 grid-rows-1">
          <h1 className="text-lg leading-6 font-semibold text-gray-900">
            {name}
          </h1>
          <div className="grid place-items-end">
            {" "}
            {renderResouceComponent()}
          </div>
        </div>
      </header>
    </>
  );
};

export default InnerStackedLayoutHeader;
