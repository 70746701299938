import { useCallback, useContext, useState } from "react";
import Modal from "../Modal";
import InputElement from "../form/InputElement";
import { EModal, ModalContext } from "../../contexts/ModalContext";
import { useAsyncCallback } from "react-async-hook";
import mutation from "../../network/mutation";
import { useAuthHeader } from "react-auth-kit";

const SendLinkDetailsModal = (): JSX.Element => {
  const [targetEmail, setTargetEmail] = useState<string>("");
  const [accessCode, setAccessCode] = useState<string>("");

  const { clearValues, getModalStatus, getModalValue, activateModal } =
    useContext(ModalContext);

  const modalStatus = getModalStatus(EModal.SendLinkDetails);
  const modalValue = getModalValue(EModal.SendLinkDetails);
  const mutateSendLinkDetails = useAsyncCallback(mutation.sendLinkDetails);

  const authHeader = useAuthHeader();
  const token = authHeader();

  const sendLinkDetails = useCallback(async () => {
    await mutateSendLinkDetails.execute(token, {
      key: modalValue?.key,
      accessCode,
      targetEmail,
    });
    clearValues();
    activateModal(EModal.Clear)
    return;
  }, [accessCode, activateModal, clearValues, modalValue?.key, mutateSendLinkDetails, targetEmail, token]);

  return (
    <Modal
      confirmButton="Send Details"
      open={modalStatus}
      onClick={sendLinkDetails}
      onClose={() => clearValues()}
    >
      <div className="space-y-12 divide-y divide-gray-200 sm:space-y-8">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Send Link Details
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Send the link details for the specified email recipient.
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <InputElement
              id={"accessUrl"}
              name={"Access URL"}
              type="text"
              disabled={true}
              value={`https://app.neptune-stormloop.io/access/${modalValue?.key}`}
            />
            <InputElement
              id={"accessCode"}
              name={"Access Code"}
              type="text"
              onChange={(event: any) => {
                const { value } = event.target;
                setAccessCode(value);
              }}
            />
            <InputElement
              id={"targetEmail"}
              name={"Email Address"}
              type="text"
              onChange={(event: any) => {
                const { value } = event.target;
                setTargetEmail(value);
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SendLinkDetailsModal;
