import { ChangeEvent, useCallback, useRef, useState } from "react";
import { useAsync, useAsyncCallback } from "react-async-hook";
import Modal from "../Modal";
import mutation from "../../network/mutation";
import InputSelectMenu from "../form/InputSelectMenu";
import FileInput from "../form/FileInput";
import queries from "../../network/queries";
import { useAuthHeader } from "react-auth-kit";

const mapDirectory = (item: any) => {
  return {
    value: item?.id,
    label: item?.title,
  };
};

const mapCompany = (item: any) => {
  return {
    value: JSON.stringify({
      id: item?.id,
      name: item?.name,
      directories: item?.directories,
    }),
    label: item?.name,
  };
};

const UploadFileModal = ({ title, callback, open }: any): JSX.Element => {
  const [company, setCompany] = useState<any>("");
  const authHeader = useAuthHeader();

  const [directory, setDirectory] = useState<any>("");
  const [directories, setDirectories] = useState<any[]>([]);

  const fileRef = useRef<HTMLInputElement>();
  const directoryRef = useRef<HTMLInputElement>();
  const companyRef = useRef<HTMLInputElement>();

  const token = authHeader()
  const { result: companyResult } = useAsync(queries.getCompanies, [authHeader()]);
  const mutateUploadFile = useAsyncCallback(mutation.uploadFile);

  const updateCompany = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const parsedValue = JSON.parse(value);

    setCompany({ id: parsedValue.id, name: parsedValue.name });
    setDirectories(parsedValue.directories);
  }, []);

  const updateDirectory = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setDirectory(value);
  }

  const uploadFile = useCallback(async () => {
    const { files } = fileRef.current as HTMLInputElement
    const [file] = files as any

    await mutateUploadFile.execute({
      directory,
      filename: file?.name,
      file: fileRef.current,
      company: company.id,
      token,
    });
    callback(false);
  }, [callback, company.id, directory, mutateUploadFile, token]);

  return (
    <Modal open={open} onClick={uploadFile} onClose={() => callback(false)}>
      <div className="space-y-12 divide-y divide-gray-200 sm:space-y-8">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              This information could be displayed publicly without an access
              token so be careful what you share.
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <InputSelectMenu
              ref={companyRef}
              onChange={updateCompany}
              title={"Company"}
              mapper={mapCompany}
              placeholder="Please select a company"
              options={companyResult?.data || []}
            />
            <InputSelectMenu
              ref={directoryRef}
              onChange={updateDirectory}
              title="Directory"
              mapper={mapDirectory}
              placeholder="Please select a directory"
              options={directories || []}
            />
            <FileInput ref={fileRef} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadFileModal;
