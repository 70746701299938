import { ChangeEvent, useCallback, useContext, useState } from "react";
import { useAsync, useAsyncCallback } from "react-async-hook";
import Modal from "../Modal";
import mutation from "../../network/mutation";
import queries from "../../network/queries";
import InputSelectMenu from "../form/InputSelectMenu";
import InputElement from "../form/InputElement";
import { EModal, ModalContext } from "../../contexts/ModalContext";
import { useAuthHeader } from "react-auth-kit";

const mapCompany = (company: any) => ({
  value: company.id,
  label: company.name,
});

const mapUser = (user: any) => ({
  value: user?.id,
  label: user?.name,
});

const CreateDirectoryModal = ({ title, execute }: any): JSX.Element => {
  const [company, setCompany] = useState<string>("");
  const [directory, setDirectory] = useState<string>("");
  const [user, setUser] = useState<string>("");

  const mutateCreateDirectory = useAsyncCallback(mutation.createDirectory);
  const authHeader = useAuthHeader();

  const { result: companyResult } = useAsync(queries.getCompanies, [
    authHeader(),
  ]);

  const { result: userResult } = useAsync(queries.listUsers, [
    authHeader(),
    company,
  ]);

  const { clearValues, getModalStatus } = useContext(ModalContext);
  const modalStatus = getModalStatus(EModal.CreateDirectory);

  const updateDirectory = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      setDirectory(value);
    },
    []
  );

  const updateCompany = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setCompany(value);
  }, []);

  const updateUser = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setUser(value);
  }, []);

  const createDirectory = useCallback(async () => {
    const token = authHeader();
    await mutateCreateDirectory.execute(token, directory, company, user);
    clearValues();
    execute(token);
  }, [
    authHeader,
    mutateCreateDirectory,
    directory,
    company,
    user,
    clearValues,
    execute,
  ]);

  return (
    <Modal
      open={modalStatus}
      onClick={createDirectory}
      onClose={() => clearValues()}
      confirmButton="Create Directory"
    >
      <div className="space-y-12 divide-y divide-gray-200 sm:space-y-8">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Create a directory to upload files and assets to.
            </p>
          </div>
          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <InputElement
              id={"directory"}
              name={"Directory"}
              type="text"
              onChange={updateDirectory}
            />
            <InputSelectMenu
              title={"Company"}
              onChange={updateCompany}
              placeholder="Please select a company from the list..."
              mapper={mapCompany}
              options={companyResult?.data || []}
            />
            <InputSelectMenu
              title={"Owner"}
              onChange={updateUser}
              placeholder="Please select a user from the list..."
              mapper={mapUser}
              options={userResult?.data || []}
            />
          </div>
          <br />
        </div>
      </div>
    </Modal>
  );
};

export default CreateDirectoryModal;
