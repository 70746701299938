import React, { ChangeEvent } from "react";

type OptionItem = {
  value: string;
  label: string;
};

type InputSelectMenuProps = {
  onChange: (event: ChangeEvent<HTMLSelectElement>) => any;
  title: string;
  options: OptionItem[];
  default?: string;
  placeholder?: string;
  mapper?: (item: any) => any;
  disabled?: boolean;
};

const InputSelectMenu = React.forwardRef<any, InputSelectMenuProps>(
  (props, ref) => {
    let options: any[] = [];

    if (typeof props.placeholder !== "undefined") {
      options.push({ value: "sample-id", label: props.placeholder });
    }

    if (typeof props.options === "undefined") {
      options = [];
    } else if (typeof props.mapper !== "undefined") {
      options = options.concat(props.options.map(props.mapper));
    } else {
      options = options.concat(props.options);
    }

    return (
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="username"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          {props?.title}
        </label>
        <select
          ref={ref}
          title={props?.title}
          id={props?.title}
          name={props?.title}
          disabled={props?.disabled}
          className="mt-1 sm:mt-0 sm:col-span-2 max-w-lg flex rounded-md shadow-sm rounded-none rounded-r-md sm:text-sm border-gray-300"
          defaultValue={props?.default}
          onChange={props?.onChange}
        >
          {options.map((item) => (
            <option
              key={item.value}
              value={item.value}
              selected={item.value === "sample-id"}
            >
              {item.label}
            </option>
          ))}
        </select>
      </div>
    );
  }
);

export default InputSelectMenu;
