/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useCallback, useEffect, useState, useContext } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { useSignOut, useAuthUser } from "react-auth-kit";
import { useLocation } from "react-router-dom";
import { EModal, ModalContext } from "../contexts/ModalContext";
import Button from "./Button";
import { LayoutProvider } from "../contexts/LayoutContext";

const user = {
  name: "Ademola Afolabi",
  email: "tom@example.com",
  imageUrl: "https://cdn-icons-png.flaticon.com/512/3135/3135715.png",
};

type NavigationItem = {
  current: boolean;
  href: string;
  name: string;
};

const initialNavigation: NavigationItem[] = [
  { name: "Dashboard", href: "/", current: false },
  { name: "Directories", href: "/directories", current: false },
  { name: "Links", href: "/links", current: false },
  { name: "Users", href: "/clients", current: false },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const StackedLayoutHeader: React.FC<any> = ({ navigation }: any) => {
  const location = useLocation();
  const { activateModal } = useContext(ModalContext);

  const renderResouceComponent = () => {
    const currentNavIndex = navigation.findIndex(
      (nav: any) => nav.href === location.pathname
    );

    const currentNav = navigation[currentNavIndex];

    if (typeof currentNav !== "undefined") {
      const { name } = currentNav;

      switch (name) {
        case "Directories":
          return (
            <Button onClick={() => activateModal(EModal.CreateDirectory)}>
              Create Directory
            </Button>
          );
      }
    }
  };

  const currentNavigation = useCallback(() => {
    const currentNavIndex = navigation.findIndex(
      (nav: any) => nav.current === true
    );
    const currentNav = navigation[currentNavIndex];

    return currentNav;
  }, [navigation]);

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 grid gap-4 grid-cols-2 grid-rows-1">
        <h1 className="text-lg leading-6 font-semibold text-gray-900">
          {currentNavigation()?.name || "Neptune"}
        </h1>
        <div className="grid place-items-end"> {renderResouceComponent()}</div>
      </div>
    </header>
  );
};

const StackedLayout: React.FC<any> = ({ children }: any) => {
  const location = useLocation();
  const [navigation, setNavigation] = useState(initialNavigation);

  const authUser = useAuthUser();
  const contextUser = authUser();
  const signOut = useSignOut();

  const updateNavigation = useCallback(() => {
    const currentNavIndex = navigation.findIndex((nav) => nav.current === true);
    const updatedNavIndex = navigation.findIndex(
      (nav) => nav.href === location.pathname
    );

    const currentNav = navigation[currentNavIndex];
    const updatedNav = navigation[updatedNavIndex];

    if (
      typeof currentNav !== "undefined" &&
      typeof updatedNav !== "undefined"
    ) {
      currentNav.current = false;
      updatedNav.current = true;

      setNavigation(navigation);
    }
  }, [location.pathname, navigation]);

  const logOutUser = useCallback(() => {
    signOut();
  }, [signOut]);

  useEffect(() => {
    if (
      ["/links", "/clients", "/directories", "/"].includes(location.pathname)
    ) {
      const navIndex = navigation.findIndex(
        (nav) => nav.href === location.pathname
      );

      const currentNav = navigation[navIndex];
      currentNav.current = true;
      setNavigation(navigation);
    }
  }, [location.pathname, navigation]);

  useEffect(() => {
    if (["/links", "/clients", "/directories", "/"].includes(location.pathname))
      updateNavigation();
  }, [location.pathname, navigation, updateNavigation]);

  const userNavigation = [{ name: "Sign out", callback: logOutUser }];

  return (
    <>
      <div className="min-h-full">
        <LayoutProvider>
          <Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
              <>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <img
                          className="h-8 w-8"
                          src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                          alt="Workflow"
                        />
                      </div>
                      <div className="hidden md:block">
                        <div className="ml-10 flex items-baseline space-x-4">
                          {navigation.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-gray-900 text-white"
                                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                "px-3 py-2 rounded-md text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-4 flex items-center md:ml-6">
                        <Menu as="div" className="ml-3 relative">
                          <div>
                            <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                              <span className="sr-only">Open user menu</span>
                              <img
                                className="h-8 w-8 rounded-full"
                                src={user.imageUrl}
                                alt=""
                              />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {userNavigation.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <a
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                      onClick={item.callback}
                                    >
                                      {item.name}
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <MenuIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="md:hidden">
                  <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                    {navigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "block px-3 py-2 rounded-md text-base font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                  <div className="pt-4 pb-3 border-t border-gray-700">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={user.imageUrl}
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-white">
                          {contextUser?.name}
                        </div>
                        <div className="text-sm font-medium text-gray-400">
                          {contextUser?.email}
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 px-2 space-y-1">
                      {userNavigation.map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as="button"
                          className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                          onClick={item.callback}
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <StackedLayoutHeader navigation={navigation} />
          <main>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              {children}
            </div>
          </main>
        </LayoutProvider>
      </div>
    </>
  );
};

export default StackedLayout;
