import React, { useCallback } from "react";
import axios from "axios";
import queries from "../../network/queries";
import { useAsync } from "react-async-hook";
import { useHSDPAuth } from "../../contexts/HSDPContext";
import { useAuthHeader } from "react-auth-kit";
import { mapContentType } from "../../utilities/mapping";
import { CONFIG } from "../../utilities/config";

const { apiUrl } = CONFIG;

const FileLayout: React.FC = () => {
  const authHeader = useAuthHeader();

  const { accessId, getHSDPToken } = useHSDPAuth();
  const { result } = useAsync(queries.queryFiles, [getHSDPToken(), accessId]);

  const onFileClick = useCallback(async (ref: any) => {
    const { id } = ref.target;
    axios({
      url: `${apiUrl}/v1/file/${id}`, //your url
      method: "GET",
      responseType: "arraybuffer",
      headers: { 
        authorization: authHeader()
      }
    }).then((response) => {
      const { headers } = response;     
      const type = headers["content-type"];
      const filename = headers["content-filename"]

      const blob = new Blob([response.data], { type: mapContentType(type) });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    });
  }, []);

  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-8 xl:gap-x-8">
        {result?.data.map((file: any) => (
          <li key={file.id} className="relative">
            <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
              <img
                src={file.image}
                alt=""
                className="object-cover pointer-events-none group-hover:opacity-75"
              />
              <button
                id={file.id}
                onClick={onFileClick}
                type="button"
                className="absolute inset-0 focus:outline-none"
              >
                <span className="sr-only">
                  View details for {file.directory}
                </span>
              </button>
            </div>
            <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
              {file.file_name}
            </p>
            <p className="block text-sm font-medium text-gray-500 pointer-events-none">
              {file.size}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FileLayout;
