import queries from "../../../network/queries";
import React from "react";
import { useAsync } from "react-async-hook";
import { useAuthHeader } from "react-auth-kit";
import Statistics from "../../../components/Statistics";
import ApplicationTable from "../../../components/tables/ApplicationTable";
import Card from "../../../layout/Card";

const ApplicationIndex: React.FC = () => {
  const authHeader = useAuthHeader();
  const { result: stats } = useAsync(queries.getStats, [authHeader()]);

  return (
    <React.Fragment>
      <Statistics value={stats} />
      <br />
      <Card>
        <ApplicationTable />
      </Card>
    </React.Fragment>
  );
};

export default ApplicationIndex;
