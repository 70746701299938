/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { CONFIG } from "../../utilities/config";

const { apiUrl } = CONFIG;

async function getFile(token: string, directory: string, file: string) {
  const { data } = await axios.get(
    `${apiUrl}/v1/${directory}/${file}`,
    { headers: { Authorization: token } }
  );
  return data;
}

async function listUsers(token: string, company?: string) {
  if (typeof company !== "undefined" && company?.length > 0) {
    const { data } = await axios.get(`${apiUrl}/v1/admin/user`, {
      params: { company },
      headers: { Authorization: token },
    });

    return data;
  }

  const { data } = await axios.get(`${apiUrl}/v1/admin/user`, {
    headers: { Authorization: token },
  });

  return data;
}

async function queryFiles(token: string, directory?: string) {
  if (typeof directory !== "undefined" && directory.length > 0) {
    const { data } = await axios.get(`${apiUrl}/v1/file`, {
      params: { directory },
      headers: { Authorization: token },
    });

    return data;
  }

  const { data } = await axios.get(`${apiUrl}/v1/file`, {
    headers: { Authorization: token },
  });
  return data;
}

async function getDirectories(token: string, company?: string) {
  if (company?.length !== 0) {
    const { data } = await axios.get(
      `${apiUrl}/v1/directory`,
      { params: { company }, headers: { Authorization: token } }
    );

    return data;
  }

  const { data } = await axios.get(
    `${apiUrl}/v1/directory`,
    { params: { company }, headers: { Authorization: token } }
  );

  return data;
}

async function getShareableLinks(token: string) {
  const { data } = await axios.get(`${apiUrl}/v1/link`, {
    headers: { Authorization: token },
  });
  return data;
}

async function getLinkInfo(token: string, link: string | undefined) {
  const { data } = await axios.get(
    `${apiUrl}/v1/link/${link}/info`,
    {
      headers: { Authorization: token },
    }
  );
  return data;
}

async function getCompanies(token: string) {
  const { data } = await axios.get(`${apiUrl}/v1/admin/company`, {
    headers: { Authorization: token },
  });
  return data;
}

async function getStats(token: string) {
  const { data } = await axios.get(`${apiUrl}/v1/stats`, {
    headers: { Authorization: token },
  });
  return data;
}

export default {
  getFile,
  getDirectories,
  getStats,
  getShareableLinks,
  getLinkInfo,
  getCompanies,
  queryFiles,
  listUsers,
};
