import { useState } from "react";
import { ContextAuthState, useHSDPAuth } from "../../contexts/HSDPContext";
import { useAsync, useAsyncCallback } from "react-async-hook";
import { useParams } from "react-router-dom";
import mutation from "../../network/mutation";
import queries from "../../network/queries";
import { useAuthHeader } from "react-auth-kit";

const AccessCode: React.FC<any> = () => {
  const { accessKey } = useParams();
  const { updateType, updateAccessId, storeHSDPToken, getHSDPToken } = useHSDPAuth();
  const authHeader = useAuthHeader();

  const token = authHeader()
  const [accessCode, setAccessCode] = useState<string>("");

  const { execute } = useAsyncCallback(mutation.verifyLink);
  const { result } = useAsync(queries.getLinkInfo, [authHeader(), accessKey]);

  const renderMessage = () =>
    result?.data?.type === "LINK_DIRECTORY"
      ? "Access Directory"
      : "Access File";

  const onSubmitAccessCode = async () => {
    const { data } = await execute(token, { accessKey, accessCode });
    const { validation } = data;

    if (data?.expired === true) {
      window.alert(
        "This link has expired, please contact your administrator to create a new link."
      );
      return;
    }

    if (validation === true) {
      if (result?.data.type === "LINK_DIRECTORY") {
        updateType(ContextAuthState.DirectoryVerified);
        updateAccessId(data.access);
        storeHSDPToken(data.token)
      }

      if (result?.data.type === "LINK_EDITABLE_DIRECTORY") {
        updateType(ContextAuthState.EditableDirectoryVerified)
        updateAccessId(data.access)
        storeHSDPToken(data.token)
      }

      if (result?.data.type === "LINK_FILE") {
        updateType(ContextAuthState.FileVerified);
        updateAccessId(data.access);
        storeHSDPToken(data.token)
      }
    }
  };

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600"
            alt="Workflow"
          />
          <h2 className="mt-8 text-center text-3xl font-bold text-gray-900">
            Highly Secured Directory Protocol (HSDP)
          </h2>
          <h3 className="mt-5 mb-5 text-2xl font-bold text-center">
            {accessKey}
          </h3>
          <p className="mt-2 text-center text-sm text-gray-600">
            <a
              href="#"
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              Enter your temporary access code below.
            </a>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label htmlFor="access-code" className="sr-only">
                  Access code
                </label>
                <div className="mt-1">
                  <input
                    id="access-code"
                    name="email"
                    type="password"
                    onChange={(event: any) => setAccessCode(event.target.value)}
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Access code"
                  />
                </div>
              </div>
              <button
                type="button"
                onClick={onSubmitAccessCode}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {renderMessage()}
              </button>
            </form>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessCode;
