export function mapContentType(file: string) {
  console.log({ file })

  switch (true) {
    case file.includes("json"):
      return "json";

    case file.includes("pdf"):
      return "pdf";

    case file.includes("jpg"):
      return "jpg";

    case file.includes("png"):
      return "png";

    case file.includes("octet-stream"):
      return "clar";

    case file.includes("xml"):
      return "xml";

    default: 
      return "clar";
  }
}
