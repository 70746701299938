import { useCallback, useContext } from "react";
import InputElement from "../form/InputElement";
import { EModal, ModalContext } from "../../contexts/ModalContext";
import Modal from "../Modal";
import mutation from "../../network/mutation";
import { useAuthHeader } from "react-auth-kit";

const DeleteLinkModal = ({ refetch }: any): JSX.Element => {
  const { clearValues, getModalValue, getModalStatus } =
    useContext(ModalContext);
  const modalStatus = getModalStatus(EModal.DeleteLink);
  const modalValue = getModalValue(EModal.DeleteLink) as any;

  const authHeader = useAuthHeader();
  const token = authHeader();

  const deleteLink = useCallback(async () => {
    await mutation.deleteLink(token, modalValue);
    refetch(token);
    clearValues();
  }, [clearValues, modalValue, refetch, token]);

  return (
    <Modal
      confirmButton="Delete Link"
      open={modalStatus}
      onClose={clearValues}
      onClick={deleteLink}
    >
      <div className="space-y-12 divide-y divide-gray-200 sm:space-y-8">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Delete Shareable Link: {modalValue?.name}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Delete the link currently shown to remove it from the system
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <InputElement
              id={"key"}
              name={"Key"}
              type="text"
              disabled={true}
              value={modalValue?.key}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteLinkModal;
