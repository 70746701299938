/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useHSDPAuth } from "../../contexts/HSDPContext";
import { mapContentType } from "../../utilities/mapping";
import { CONFIG } from "../../utilities/config";
import { useAuthHeader } from "react-auth-kit";

const { apiUrl } = CONFIG;

export default () => {
  const { accessId, getHSDPToken } = useHSDPAuth();
  const [counter, setCounter] = useState(0);

  const onFileClick = useCallback(async (ref: any) => {
    const { id } = ref.target;
    axios({
      url: `${apiUrl}/v1/file/${id}`, //your url
      method: "GET",
      responseType: "arraybuffer",
      headers: { 
        authorization: getHSDPToken()
      }
    }).then((response) => {
      const { headers } = response;     
      const type = headers["content-type"];
      const filename = headers["content-filename"]

      const blob = new Blob([response.data], { type: mapContentType(type) });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    });
  }, []);

  useEffect(() => {
    if (counter === 0) {
      onFileClick(accessId);
    }
  }, [accessId, counter, onFileClick]);

  return <div>File Download</div>;
};
